<template>
  <div class="card p-shadow-6">
    <h1>Helpdesk - Assuntos Bloqueados</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="list"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="subject"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('addBlockedSubjects')"
          >
            <Button icon="pi pi-plus" @click="newSubject()" />
          </div>
        </template>
        <Column
          field="subject"
          filterField="subject"
          header="Texto"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="condition_id"
          filterField="condition_id"
          header="Condição"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{ slotProps.data.condition.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="conditions"
              optionValue="id"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.active == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="created_user.username"
          filterField="created_user.username"
          header="Adicionado"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{
              slotProps.data.created_user
                ? slotProps.data.created_user.username
                : ""
            }}
            |
            {{ slotProps.data.created_at }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="updated_user.username"
          filterField="updated_user.username"
          header="Atualizado"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{
              slotProps.data.updated_user
                ? slotProps.data.updated_user.username
                : ""
            }}
            |
            {{ slotProps.data.updated_at }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="
                $store.state.auth.user.isEsa || can('updateBlockedSubjects')
              "
              icon="pi pi-pencil"
              v-tooltip.left="'Editar'"
              class="p-button-sm p-button-rounded p-button-success"
              @click="editSubject(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="subjectModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        'Blacklist' +
        (subjectModel.id == undefined ? ' Adicionar' : ' Actualizar') +
        ' Assunto'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form
        name="aclCtegoryForm"
        class="p-col-12"
        @submit.prevent="saveTraining"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <InputText
                v-validate="'required'"
                v-model="subjectModel.subject"
                v-bind:class="[
                  { 'p-invalid': errors.has('subject') },
                  'form-control',
                ]"
                :name="'subject'"
              />
              <label for="subject">Texto a ignorar</label>
            </span>
            <small v-if="errors.has('subject')" class="p-error" role="alert">
              Texto é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-8 p-mt-2">
            <span class="p-float-label">
              <dropdown
                v-model="subjectModel.condition_id"
                v-validate="'required'"
                :options="conditions"
                optionLabel="name"
                optionValue="id"
                :filter="true"
                :showClear="true"
                :name="'condition'"
                v-bind:class="[
                  { 'p-invalid': errors.has('condition') },
                  'form-control',
                ]"
              />
              <label for="condition">Condição</label>
            </span>
            <small v-if="errors.has('condition')" class="p-error" role="alert">
              Condição é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-4 p-mt-2" v-if="subjectModel.id">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="active">Ativa</label>
            </span>
            <InputSwitch
              v-model="subjectModel.active"
              name="active"
              style="'top: 3px;"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveSubject"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ticketsEmailService from "../services/ticketsEmails.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "HelpdeskBlockedSubjects",
  data() {
    return {
      loading: true,
      list: [],
      conditions: [],
      filters: {
        subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "created_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "updated_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
        condition_id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      subjectModel: { showForm: false },
    };
  },
  async created() {
    this.list = await ticketsEmailService.getSubjectToIgnore();
    this.conditions = await ticketsEmailService.getSubjectToIgnoreConditions();
    this.loading = false;
  },
  methods: {
    closeForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.subjectModel = { showForm: false });
    },
    newSubject() {
      return (this.subjectModel.showForm = true);
    },
    editSubject(subject) {
      return (this.subjectModel = {
        showForm: true,
        id: subject.id,
        subject: subject.subject,
        active: subject.active == 1 ? true : false,
        condition_id: subject.condition_id,
      });
    },
    saveSubject() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.subjectModel.id === undefined) {
          return this.saveNewSubject();
        }

        return this.updateSubject();
      });
    },
    saveNewSubject() {
      let bodyParams = {
        subject: this.subjectModel.subject,
        condition_id: this.subjectModel.condition_id,
      };

      return ticketsEmailService
        .addSubjectToIgnore(bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o assunto na blacklist",
              life: 3000,
            });
          }
          this.list.push(response);
          this.$toast.add({
            severity: "success",
            summary: "Assunto inserido",
            detail: "O assunto foi inserida com sucesso na blacklist",
            life: 3000,
          });
          return this.closeForm();
        });
    },
    updateSubject() {
      let bodyParams = {
        subject: this.subjectModel.subject,
        condition_id: this.subjectModel.condition_id,
      };

      if (this.subjectModel.active !== undefined) {
        bodyParams.active = this.subjectModel.active;
      }

      const areYou = (itemAux) => {
        return itemAux.id == this.subjectModel.id;
      };
      return ticketsEmailService
        .updateSubjectToIgnore(this.subjectModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar a blacklist",
              life: 3000,
            });
          }
          let index = this.list.findIndex(areYou);
          if (index >= 0) {
            this.list.splice(index, 1);
          }
          this.list.push(response);
          this.subjectModel.email = response;
          this.$toast.add({
            severity: "success",
            summary: "Blacklist atualizada",
            detail: "A Blacklist foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeForm();
        });
    },
  },
};
</script>
